export const About = (props) => {
  return (
    <div id="about">
      <div className="container">
        <div className="row img-text-container">
          <div className="logo-img-container col-xs-12">
            <img
              src="https://i.ibb.co/XFbrk81/Taylor-Ann-Scott-Foundation-Logo-Final-01.jpg"
              className="img-responsive logo-image-about"
              alt=""
            />
          </div>
          <div className="col-xs-12">
            <div className="about-text">
              <h2>Our Story</h2>
              <p>
                The Scott Family, David, Beth, Taylor and Mary Beth have been blessed with opportunity, love, and support from our
                family, friends, and the community. We have also experienced tragedy. In 2017, our eldest daughter, age 24, was
                diagnosed with stage 4 Cancer, ( Metastatic Adnocarcinoma). As you might imagine, our family experienced denial,
                fear, hope, and pain and in late 2019, we lost Taylor after a long fought battle. This story is about what we
                learned and how we grew emotionally and spiritualty through this experience. As Taylor battled cancer, we
                witnessed Taylor’s strength and determination through her courage, compassion, and faith in God. Taylor focused on
                helping others by providing emotional support and fundraising in an effort to help others and her efforts were
                inspirational to our family and others. We were inspired by the doctors and nurses who commit their lives helping
                others through this journey every day. We gained a heightened sense of empathy for our community as we entered the
                cancer world, which in some ways affects most all of us at some point. We became researchers and networkers in an
                effort to defy the odds of a terminal diagnosis. We would be remiss if I didn’t mention our dogs, Norman,, Winnie,
                Sophie and Blue who were always there for Taylor and our family and still offer us support every day. Taylor is a
                bright light who will continue to shine in our lives and through the ongoing work of the Taylor Ann Scott
                Foundation whose works are inspired by her compassion for all.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
