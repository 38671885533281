import { useState } from "react";

const initialState = {
  name: "",
  email: "",
  message: "",
};
export const Contact = (props) => {
  const [{ name, email, message }, setState] = useState(initialState);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setState((prevState) => ({ ...prevState, [name]: value }));
  };
  const clearState = () => setState({ ...initialState });

  const handleSubmit = (e) => {
    e.preventDefault();
    window.open(
      `mailto:support@taylorannscottfoundation.com?subject=${encodeURIComponent("From: " + name)}&body=${encodeURIComponent(
        message
      )}`
    );
  };
  return (
    <div>
      <div id="contact">
        <div className="container">
          <div className="col-md-8">
            <div className="row">
              <div className="section-title">
                <h2>Get In Touch</h2>
                <p>Please fill out the form below to send us an email and we will get back to you as soon as possible.</p>
              </div>
              <form name="sentMessage" validate onSubmit={handleSubmit}>
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group">
                      <input
                        type="text"
                        id="name"
                        name="name"
                        className="form-control"
                        placeholder="Name"
                        required
                        onChange={handleChange}
                      />
                      <p className="help-block text-danger"></p>
                    </div>
                  </div>
                </div>
                <div className="form-group">
                  <textarea
                    name="message"
                    id="message"
                    className="form-control"
                    rows="4"
                    placeholder="Message"
                    required
                    onChange={handleChange}
                  ></textarea>
                  <p className="help-block text-danger"></p>
                </div>
                <div id="success"></div>
                <button type="submit" className="btn btn-custom btn-lg">
                  Send Message
                </button>
              </form>
            </div>
          </div>
          <div className="col-md-3 col-md-offset-1 contact-info">
            <div className="contact-item">
              <h3>Contact Info</h3>
              <p>
                <span>
                  <i className="fa fa-map-marker"></i> Address
                </span>
                {props.data ? props.data.address : "loading"}
              </p>
            </div>
            <div className="contact-item">
              <p>
                <span>
                  <i className="fa fa-phone"></i> Phone
                </span>
                {props.data ? props.data.phone : "loading"}
              </p>
            </div>
            <div className="contact-item">
              <p>
                <span>
                  <i className="fa fa-envelope-o"></i> Email
                </span>
                {props.data ? props.data.email : "loading"}
              </p>
            </div>
            <div
              className="contact-item external-link"
              onClick={() => window.location.assign("https://instagram.com/taylorannscottfoundation?igshid=YmMyMTA2M2Y=")}
            >
              <span>
                <i className="fa fa-instagram"></i> Instagram
              </span>
              {props.data ? props.data.instagram : "loading"}
            </div>
            <div
              className="contact-item external-link"
              onClick={() => window.location.assign("https://www.facebook.com/taylorannscottfoundation/")}
            >
              <p>
                <span>
                  <i className="fa fa-facebook"></i> Facebook
                </span>
                {props.data ? props.data.facebook : "loading"}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
